<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'faq.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="mx-form-header">
          <h4 class="mb-3">{{ $t('faq.edit') }}</h4>
        </div>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`faq.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`faq.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>
            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'faq.show', params: {id: $route.params.faq_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>

      </div>
    </div>

  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft,
  PhTrashSimple
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft, PhTrashSimple},
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      form_elements: [
        'welcome_text',
      ],
      form: {
        id: null,
        company_id: null,
        customer_id: null,
        welcome_text: null,
      },
      errors: [],
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.getFaq()
  },
  methods: {
    getFaq() {
      this.$store.dispatch('faqs', this.$route.params.company_id).then(() => {
        this.form = this.$store.getters.faqs.find(faq => faq.id === parseInt(this.$route.params.faq_id))
        this.preloader = false
      })
    },
    save() {
      this.button_disabled = true;
      this.$store.dispatch('faqEdit', this.form).then(() => {
        this.$router.replace({
          name: 'faq.show', params: {company_id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number)\b/g)[0]
    }
  }
}
</script>
